export const data1 = [
  {
    title: "What is NFT?",
    text: "An NFT (Non-Fungible Token) is a type of digital asset that is unique and cannot be replicated. It is stored on a blockchain and can represent anything from artwork to collectibles, digital assets, or even real-world assets.",
  },
  {
    title: "How does an NFT work?",
    text: "An NFT works by using blockchain technology to create a unique token that can be used to represent ownership of a digital asset. The token is stored on the blockchain and can be transferred between users, allowing for the secure transfer of ownership.",
  },
  {
    title: "What is the Price Pool?",
    text: "$600K",
  },
];
export const data2 = [
  {
    title: "Is there any presale?",
    text: "No presale, mint will be available for all participants",
  },
  {
    title: "Wen Mint?",
    text: "Will Announce soon...",
  },
  {
    title: "What is your contract address ?",
    text: "Coming Soon",
  },
];
